<template>
  <div class="details">
    <div class="title">{{data.cname}}</div>
    <div class="line" v-if="data.cname">
      <div class="line-title">【 操作名字 】</div>
      <div class="line-text" v-html="data.cname"></div>
    </div>
    <div class="line" v-if="data.alias">
      <div class="line-title">【 别&nbsp&nbsp&nbsp&nbsp&nbsp名 】</div>
      <div class="line-text" v-html="data.alias"></div>
    </div>
    <div class="column" v-if="data.indications">
      <div class="line-title">【 适&nbsp应&nbsp证 】</div>
      <div class="line-text" v-html="data.indications"></div>
    </div>
    <div class="column" v-if="data.contra_indications">
      <div class="line-title">【 禁&nbsp忌&nbsp证 】</div>
      <div class="line-text" v-html="data.contra_indications"></div>
    </div>
    <div class="column" v-if="data.preparation">
      <div class="line-title">【 准&nbsp&nbsp&nbsp&nbsp&nbsp备 】</div>
      <div class="line-text" v-html="data.preparation"></div>
    </div>
    <div class="column" v-if="data.method">
      <div class="line-title">【 方&nbsp&nbsp&nbsp&nbsp&nbsp法 】</div>
      <div class="line-text" v-html="data.method"></div>
    </div>
    <div class="line" v-if="data.download">
      <div class="line-title">【 操&nbsp&nbsp&nbsp&nbsp&nbsp作 】</div>
      <div class="line-text">
        <a href="" @click.prevent="onDownload(data, '8')">下 载</a>
      </div>
    </div>
  </div>
</template>

<script>
  import mixins from '../../mixins'
  export default {
    name: "operatingDisciplineDetails",
    mixins: [
      mixins
    ],
    data(){
      return {
        type: '8',
      }
    },
    created() {
      if (this.$route.query.id || this.$route.query.ID){
        this.getData({ id: this.$route.query.id || this.$route.query.ID, type: this.type })
      }
    }
  }
</script>

